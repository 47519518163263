<template>
  <AdminPageFrame class="w-main-container">
    <PageTitle>
      {{ $route.params.id ? trans('PAGE_TITLE:PartnerCodeEdit') : trans('PAGE_TITLE:PartnerCodeAdd') }}
    </PageTitle>
    <t-card class="w-full">
      <FieldWrapper class="mb-3" :label="trans('LABEL:Code')">
        <TextField v-model="partnerCode.code">

        </TextField>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:Partner')">
        <SelectField v-model="partnerCode.partner" :options="partnersSelectData" :placeholder="''">

        </SelectField>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:Percent')">
        <TextField rules="required" v-model="partnerCode.percentDays">

        </TextField>
      </FieldWrapper>
      <div class="flex flex-row space-x-3">
        <FieldWrapper class="mb-3" :label="trans('LABEL:ValidFrom')">
          <t-datepicker
            class="border border-grayed rounded"
            dateFormat="Y-m-d H:i"
            userFormat="d F Y H:i"
            :weekStart="1"
            :lang="langLocale"
            :timepicker="true"
            v-model="partnerCode.validFrom">
          </t-datepicker>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:ValidTo')">
          <t-datepicker
            class="border border-grayed rounded"
            dateFormat="Y-m-d H:i"
            userFormat="d F Y H:i"
            :weekStart="1"
            :lang="langLocale"
            :timepicker="true"
            v-model="partnerCode.validTo">
          </t-datepicker>
        </FieldWrapper>
      </div>
      <FieldWrapper class="mb-3" :label="trans('LABEL:Limit')">
        <TextField rules="required" v-model="partnerCode.limit">

        </TextField>
      </FieldWrapper>
      <div class="flex flex-row justify-end">
        <Button :text="trans('BUTTON:Save')" :loading="partnerCodesSaving" @clicked="storePartnerCodeAction">
        </Button>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SelectField from "@/utils/crud/components/field-types/Select";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnerShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    SelectField,
    TextField,
  },
  data() {
    return {
      partnerCode: {},

    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchShowPartnerCodeRequest(val);
        }
      }
    },
    partnerCodeLoaded(val) {
      if(val) {
        this.partnerCode = structuredClone(this.partnerCodeData)
      }
    }
  },
  computed: {
    ...mapGetters('partnerCodes', ['partnerCodesSaving', 'partnerCodeLoaded', 'partnerCodeData']),
    ...mapGetters('adminPartners', ['partnersSelectData']),
    ...mapGetters('lang', ['langLocale'])
  },
  methods: {
    ...mapActions('partnerCodes', ['storePartnerCodeData', 'fetchShowPartnerCodeRequest', 'updatePartnerCodeData']),
    ...mapActions('adminPartners', ['fetchPartnersSelectRequest']),
    storePartnerCodeAction() {
      if(this.$route.params.id) {
        this.updatePartnerCodeData(this.partnerCode);
        return;
      }
      this.storePartnerCodeData(this.partnerCode);
    }
  },
  mounted() {
    this.fetchPartnersSelectRequest();
  }
}
</script>
